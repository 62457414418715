// workaround for https://github.com/Leaflet/Leaflet.draw/issues/1026
// comment https://github.com/Leaflet/Leaflet.draw/issues/1026#issuecomment-986702652
window.type = true;

class BakArray {
  constructor(value, previous = null) {
    this.value = value;
    this.previous = value.previous || previous || null;
  }
}

SVG.Element.prototype.draw.defaults = {
  snapToGrid: 1, // Snaps to a grid of `snapToGrid` px
  drawCircles: true,
  closeLastPoint: false,
  circleDiameter: 25,
  maxCircleDiameter: 20,
  fill: '',
  minPoints: null,
  maxPoints: null,
  skipPointConstrain: false
};

SVG.Element.prototype.draw.extend('line polyline polygon', {
  init: function (e) {
    // When we draw a polygon, we immediately need 2 points.
    // One start-point and one point at the mouse-position
    this.set = new SVG.Set();

    var p = this.startPoint,
      arr = [
        [p.x, p.y],
        [p.x, p.y]
      ];

    this.el.plot(arr);
    this.el.fire('drawpoint', {event: e, p: {x: p.x, y: p.y}, m: this.m});

    // We draw little circles around each point
    // This can be disabled by setting { drawCircles: false } option
    if (this.options.drawCircles) {
      if (this.options?.circleDiameter > 0) {
        this.circleDiameter = this.options.circleDiameter;
      }

      if (this.options?.maxCircleDiameter > 0) {
        this.maxCircleDiameter = this.options.maxCircleDiameter;
      }

      this.fill = this.options.fill;
      this.drawCircles();
    }

    this.parent.on('contextmenu', e => {
      e.preventDefault();
      this.complete();
    });
  },

  // The calc-function sets the position of the last point to the mouse-position (with offset ofc)
  calc: function (e) {
    var arr = this.el.array().valueOf();
    arr.pop();

    if (e) {
      var p = this.transformPoint(e.clientX, e.clientY);
      arr.push(this.snapToGrid([p.x, p.y]));
    }
    this.el.plot(arr);
  },
  point: function (e) {
    let hasToClick = true;
    if (!this.options.skipPointConstrain) {
      const elementsBehind = document.elementsFromPoint(event.clientX, event.clientY);
      hasToClick = elementsBehind.some(element => element.tagName === 'IMG');
    }

    if (!hasToClick) {
      return;
    }

    const maxCircleDiameter = this.options.maxCircleDiameter;

    if (this.options.maxPoints && this.options.minPoints) {
      if (
        this.options.maxPoints <= this.el._array.value.length &&
        this.el._array.value.length >= this.options.minPoints
      ) {
        this.done();
        return;
      }
    }

    if (this.el.type.indexOf('poly') > -1) {
      // Add the new Point to the point-array
      var p = this.transformPoint(e.clientX, e.clientY),
        arr = this.el.array().valueOf();

      if (this.options.drawCircles) {
        // distance between two points (last and first)
        const firstSvgPoint = this.set.get(0);

        const distanceX = Math.pow(firstSvgPoint.x() - p.x, 2);
        const distanceY = Math.pow(firstSvgPoint.y() - p.y, 2);

        const distance = Math.sqrt(distanceX + distanceY, 2);

        const circleBigger = this.circleDiameter * 2;
        const circleSmall = this.circleDiameter * 1.25;
        const checkDistance = firstSvgPoint.attr('class') === 'bigger' ? circleBigger : circleSmall;

        if (distance < checkDistance && this.el._array.value.length > 3) {
          if (this.options.closeLastPoint) {
            this.done();
          }
          return;
        } else {
          if (distance < checkDistance) {
            return;
          }
          arr.push(this.snapToGrid([p.x, p.y]));

          this.el.plot(arr);

          if (this.options.drawCircles) {
            this.drawCircles();
          }

          // Fire the `drawpoint`-event, which holds the coords of the new Point
          this.el.fire('drawpoint', {event: e, p: {x: p.x, y: p.y}, m: this.m});

          return;
        }
      }
    } else {
      if (this.el.type.indexOf('line') > -1) {
        var p = this.transformPoint(e.clientX, e.clientY);
        const points = this.el.array().value;
        const hasNoPoints = points[0][0] === points[1][0] && points[0][0] == 0;
        const hasOnePoint = (points[0][0] === points[1][0] && points[0][1] === points[1][1]) || points[1][0] < 0;

        if (hasNoPoints || hasOnePoint) {
          return;
        }

        this.stop(e);
        return;
      }
    }

    // We are done, if the element is no polyline or polygon
    this.stop(e);
  },
  drawCircles: function (circleDiameter) {
    if (circleDiameter) {
      this.circleDiameter = circleDiameter > this.maxCircleDiameter ? this.maxCircleDiameter : circleDiameter;
    }

    var array = this.el.array().valueOf();

    if (this.options.closeLastPoint) {
      const firstSvgCircleIndex = 0;
      let firstSvgPoint = null;
      if (this.set.get(firstSvgCircleIndex)) {
        firstSvgPoint = this.set.get(firstSvgCircleIndex);
        firstSvgPoint.off();
      }
    }

    this.set.each(function () {
      this.remove();
    });

    this.set.clear();

    if (!this.el.remember('hasToDraw')) {
      array.pop();
    }

    for (var i = 0; i < array.length - 1; ++i) {
      this.p.x = array[i][0];
      this.p.y = array[i][1];

      var p = this.p.matrixTransform(this.parent.node.getScreenCTM().inverse().multiply(this.el.node.getScreenCTM()));
      this.set.add(this.parent.circle(this.circleDiameter).fill(this.fill).center(p.x, p.y));
    }

    if (this.options.closeLastPoint) {
      const firstSvgCircleIndex = 0;
      firstSvgPoint = this.set.get(firstSvgCircleIndex);
      firstSvgPoint.front();
      firstSvgPoint.fill(this.fill);
      firstSvgPoint.mouseover(() => {
        firstSvgPoint.animate(100).attr({r: this.circleDiameter * 1.15, fill: this.fill, class: 'bigger'});
      });
      firstSvgPoint.mouseleave(() => {
        firstSvgPoint.attr({r: this.circleDiameter / 2, fill: this.fill, class: ''});
      });
    }
  },
  clean: function () {
    // Remove all circles
    this.set.each(function () {
      this.remove();
    });

    this.set.clear();
    this.bak.pointer = null;
    delete this.set;
  },

  undo: function () {
    if (this.set.length() > 1) {
      this.bak.pointer = new BakArray([...this.el.array().value], this.bak.pointer);
      const membersToRemove = -2;
      this.el.array().value.splice(membersToRemove, 1);
      if (this.options.drawCircles) {
        this.drawCircles();
      }
      this.el.plot(this.el.array());
      this.el.fire('undopoint');
    }
  },
  redo: function () {
    if (!!this.bak.pointer) {
      this.el.array().value = this.bak.pointer.value;
      if (this.options.drawCircles) {
        this.drawCircles();
      }
      this.el.plot(this.el.array());
      this.bak.pointer = this.bak.pointer.previous;
    }
  },
  stop: function (event) {
    if (event) {
      this.update(event);
    }

    // Plugin may want to clean something
    if (this.clean) {
      this.clean();
    }

    // Unbind from all events
    SVG.off(window, 'mousemove.draw');
    this.parent.off('click.draw');
    this.parent.off('contextmenu');

    // remove Refernce to PaintHandler
    this.el.forget('_paintHandler');

    // clear bak
    this.bak.pointer = null;

    // overwrite draw-function since we never need it again for this element
    this.el.draw = function () {};

    // Fire the `drawstop`-event
    this.el.fire('drawstop');
  },
  cancel: function (event) {
    if (event) {
      this.update(event);
    }

    // Plugin may want to clean something
    if (this.clean) {
      this.clean();
    }

    // Unbind from all events
    SVG.off(window, 'mousemove.draw');
    this.parent.off('click.draw');
    this.parent.off('contextmenu');

    // remove Refernce to PaintHandler
    this.el.forget('_paintHandler');

    // clear bak
    this.bak.pointer = null;

    // overwrite draw-function since we never need it again for this element
    this.el.draw = function () {};

    // Fire the `drawstop`-event
    this.el.fire('drawcancel');
  },
  complete: function () {
    //At least 3 points should be required to draw a polygon

    if (this.options.maxPoints && this.options.minPoints) {
      if (
        this.el._array.value.length > this.options.maxPoints ||
        this.el._array.value.length < this.options.minPoints
      ) {
        return;
      }
    }

    if (this.el.type.indexOf('line') > -1) {
      const points = this.el.array().value;
      const hasNoPoints = points[0][0] === points[1][0] && points[0][0] == 0;
      const hasOnePoint = (points[0][0] === points[1][0] && points[0][1] === points[1][1]) || points[1][0] < 0;

      if (hasNoPoints || hasOnePoint) {
        return;
      }

      this.stop();
      this.el.fire('drawcomplete');
      return;
    }

    if (this.el._array.value.length > 3) {
      this.calc();
      if (this.options.drawCircles && this.options.closeLastPoint) {
        const firstSvgCircleIndex = 0;
        let firstSvgPoint = null;
        if (this.set.get(firstSvgCircleIndex)) {
          firstSvgPoint = this.set.get(firstSvgCircleIndex);
          firstSvgPoint.off();
        }
      }
      this.stop();
      this.el.fire('drawcomplete');
    }
  },
  circleDiameter: 25,
  maxCircleDiameter: 10,
  bak: {pointer: null}
});

SVG.Element.prototype.draw.extend('rect image', {
  initMouseDown: function () {
    const middleMouseButton = 1;

    this.parent.on('mousedown', e => {
      if (e.button === middleMouseButton) {
        return;
      }

      this.timeoutRef = setTimeout(() => {
        this.start(e);
        this.parent.off('mousedown');
        this.parent.off('mouseup');
      }, this.mouseHoldTime);
    });

    this.parent.on('mouseup', e => {
      if (e.button === middleMouseButton) {
        return;
      }

      this.start(e, false);
      clearTimeout(this.timeoutRef);
      this.parent.off('mousedown');
      this.parent.off('mouseup');
    });
  },
  start: function (event, isMouseHold = true) {
    this.m = this.el.node.getScreenCTM().inverse();
    this.offset = {x: window.pageXOffset, y: window.pageYOffset};
    this.options.snapToGrid *= Math.sqrt(this.m.a * this.m.a + this.m.b * this.m.b);
    this.startPoint = this.snapToGrid(this.transformPoint(event.clientX, event.clientY));

    if (this.init) {
      this.init(event);
    }

    this.start = this.point;
    this.el.fire('drawstart', {event: event, p: this.p, m: this.m});

    const eventNameMouse = isMouseHold ? 'mouseup' : 'mousedown';
    SVG.on(window, 'mousemove.draw mouseup.draw mousedown.draw', e => {
      this.update(e);
      if (e.type === eventNameMouse) {
        this.point(e);
      }
    });
  },
  init: function (e) {
    var p = this.startPoint;
    this.el.attr({x: p.x, y: p.y, height: 0, width: 0});
  },

  calc: function (e) {
    var rect = {
        x: this.startPoint.x,
        y: this.startPoint.y
      },
      p = this.transformPoint(e.clientX, e.clientY);

    rect.width = p.x - rect.x;
    rect.height = p.y - rect.y;

    // Snap the params to the grid we specified
    this.snapToGrid(rect);

    // When width is less than zero, we have to draw to the left
    // which means we have to move the start-point to the left
    if (rect.width < 0) {
      rect.x = rect.x + rect.width;
      rect.width = -rect.width;
    }

    // ...same with height
    if (rect.height < 0) {
      rect.y = rect.y + rect.height;
      rect.height = -rect.height;
    }

    // draw the element
    this.el.attr(rect);
  },
  point: function (event) {
    // If this function is not overwritten we just call stop
    this.stop();
  },
  stop: function (event) {
    if (event) {
      this.update(event);
    }

    // Plugin may want to clean something
    if (this.clean) {
      this.clean();
    }

    // Unbind from all events
    SVG.off(window, 'mousedown.draw mousemove.draw mouseup.draw');
    this.parent.off('mousedown.draw');

    // remove Refernce to PaintHandler
    this.el.forget('_paintHandler');

    // overwrite draw-function since we never need it again for this element
    this.el.draw = function () {};

    // Fire the `drawstop`-event
    this.el.fire('drawstop');
  },
  cancel: function (event) {
    if (event) {
      this.update(event);
    }

    // Plugin may want to clean something
    if (this.clean) {
      this.clean();
    }

    // Unbind from all events
    SVG.off(window, 'mousedown.draw mousemove.draw mouseup.draw');
    this.parent.off('mousedown.draw');

    // remove Refernce to PaintHandler
    this.el.forget('_paintHandler');

    // overwrite draw-function since we never need it again for this element
    this.el.draw = function () {};

    // Fire the `drawstop`-event
    this.el.fire('drawcancel');
  },
  timeoutRef: 0,
  mouseHoldTime: 300
});
